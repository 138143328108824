import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CredentialsService } from "../core/credentials.service";
import { AdminType } from "./admin/admin.model";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  constructor(
    private credentialService: CredentialsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.router.url == "/") {
      if (this.credentialService.isAdminType(AdminType.CMO)) {
        this.router.navigate(["/logs"]);
      } else {
        this.router.navigate(["/dashboard"]);
      }
    }
  }
}
