import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export const enum ModalConfirmActions {
  ACCEPT = 'accept',
  DENY = 'deny'
}

export const confirmModalConfig = {
  width: "600px",
  height: "450px",
  maxHeight: "628px",
  disableClose: true,
  autoFocus: false,
  restoreFocus: false,
  panelClass: "custom-dialog-container-terms",
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  message: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.message = this.data.message;
  }

  close() {
    this.dialogRef.close();
  }

  closeWithAction( action:string ) {
    this.dialogRef.close(action);
  }

  modalAction( event: string ) {
    const action = event === ModalConfirmActions.ACCEPT ? ModalConfirmActions.ACCEPT : ModalConfirmActions.DENY;
    this.closeWithAction(action);
  }

}
