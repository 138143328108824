import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { CredentialsService } from "./core/credentials.service";
import { I18nService } from "./core/i18n.service";
import { map, retry, catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public credentialsService: CredentialsService,
    public translateService: TranslateService,
    private router: Router
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.credentialsService.credentials
      ? this.credentialsService.credentials
      : "";
    if (!token) {
      req = req.clone({
        url: environment.base_url + req.url,
        headers: req.headers.set(
          "Accept-Language",
          this.translateService.currentLang
        ),
      });
    } else {
      req = req.clone({
        url: environment.base_url + req.url,
        headers: req.headers.set(
          "Accept-Language",
          this.translateService.currentLang
        ),
        setHeaders: {
          // "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        },
      });
    }
    return next.handle(req).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          this.credentialsService.setCredentials();
          this.router.navigate(["/login"]);
        } else {
          // console.log("http error: ",error)
        }
        return throwError(error);
      })
    );
  }
}
