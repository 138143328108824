<div>
  <ol>
    <li *ngFor="let msg of data" class="data">
      <img src="../../../assets/images/close.svg" style="margin-right: 2%;" />{{
        msg
      }}
    </li>
  </ol>
  <!-- <div class="dismiss">
      <button mat-icon-button (click)="snackBarRef.dismiss()">
          <mat-icon>close</mat-icon>
        </button>
    </div> -->
</div>
