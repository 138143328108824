<!-- my-loader.component.html -->
<div class="progress-loader" [hidden]="!loading">
  <div class="loading-spinner">
    <!-- <img src="../../assets/images/loader.svg">
        <span class="loading-message">Please wait...</span> -->
    <ng-lottie
      width="100px"
      height="100px"
      [options]="options"
      (animationCreated)="animationCreated($event)"
    ></ng-lottie>
  </div>
</div>
