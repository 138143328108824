import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[shdIdentification]',
  })
export class IdentificationDirective {
    @Input() identificationType: string = '';
    constructor(private el: ElementRef) {}
  
    @HostListener('input', ['$event']) onInputChange(event: any) {
      const initialValue = this.el.nativeElement.value;
      const pattern = /[0-9]/;
      const onlyNumbers = pattern.test(initialValue);
      let identification = ''
      if(onlyNumbers) {
        identification = this.identificationType + '-'
      }
      this.el.nativeElement.value = `${identification}${initialValue.replace(/[^0-9]*/g, '')}`
      if (initialValue !== this.el.nativeElement.value) {
        event.stopPropagation();
      }
    }
  }