import { Injectable } from "@angular/core";
import {
  HttpParams,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { WaitingList } from "./waitingList";
import { tap, catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Post } from "./post";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageComponent } from "./message/message.component";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  getWaitingListUrl = "/consultations";
  postUrl = "/post";
  constructor(private httpClient: HttpClient, public snackBar: MatSnackBar) {}

  // addPost(post: Post): Observable<Post> {
  //   return this.httpClient.post<Post>(this.postUrl, post).pipe(
  //     catchError(this.handleError(post))
  //   );
  // }

  // getWaiting() {
  //   let param = {
  //     "status[0]": 0,
  //     sort: "updatedAt",
  //   };
  //   let params = new HttpParams();
  //   for (const key in param) {
  //     if (param.hasOwnProperty(key)) {
  //       params = params.set(key, param[key]);
  //     }
  //   }
  //   return this.httpClient
  //     .get<WaitingList[]>(this.getWaitingListUrl, { params: params })
  //     .pipe(
  //       catchError(this.handleError<WaitingList[]>([]))
  //     );
  //   //this.httpClient.get<any>(this.getWaitingListUrl, { params: params });
  // }

  // private handleError<T>(result = {} as T) {
  //   return (error: HttpErrorResponse): Observable<T> => {
  //     console.error(error);
  //     return of(result);
  //   };
  // }

  showToastMessage(message: string, panelClass: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: message,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
      panelClass: panelClass,
    });
  }
}
