import { Component, OnInit, Inject } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";

@Component({
  selector: "app-multiple-error-message",
  templateUrl: "./multiple-error-message.component.html",
  styleUrls: ["./multiple-error-message.component.scss"],
})
export class MultipleErrorMessageComponent implements OnInit {
  constructor(
    public snackBarRef: MatSnackBarRef<MultipleErrorMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  ngOnInit(): void {}
}
