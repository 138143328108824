<mat-toolbar>
  <mat-toolbar-row>
    <img
      src="../../../assets/images/header-logo.svg"
      width="90px"
      (click)="gotoLandingPage()"
      style="cursor: pointer;"
    />
    <span class="example-spacer"></span>
    <!-- global search-->
    <span *ngIf="adminType === adminTypes.CMO">
      <form [formGroup]="searchForm">
        <!-- {{booksName}} -->
        <mat-form-field
          appearance="outline"
          style="height: 25px; width: 400px; font-size: 0.875rem;"
        >
          <input
            matInput
            placeholder="{{ 'Search' | translate }}"
            aria-label="State"
            [matAutocomplete]="auto"
            formControlName="searchBar"
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="gotoPatientDetails($event.option.value)"
          >
            <mat-option
              style="font-size: 0.875rem !important;"
              *ngIf="!isLoading && searchResult.length < 1"
              class="is-loading"
              >No results found</mat-option
            >
            <ng-container *ngIf="!isLoading">
              <mat-option
                style="font-size: 0.75rem !important;"
                *ngFor="let option of searchResult"
                [value]="option"
              >
                <div class="search-details">
                  <span
                    class="search-list"
                    style="text-transform: capitalize;"
                    >{{ option.firstName + " " + option.lastName }}</span
                  >

                  <span class="search-list">{{
                    option.identificationNumber
                  }}</span>

                  <span
                    class="search-list"
                    *ngIf="option.parent; else childSection"
                    >{{
                      option.parent.countryCode +
                        "-" +
                        option.parent.phoneNumber
                    }}</span
                  >
                  <ng-template class="search-list" #childSection>
                    {{ option.countryCode + "-" + option.phoneNumber }}
                  </ng-template>
                </div>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
        <!-- <span *ngIf="searchResult.length === 0">Not Found</span>     -->
      </form>
    </span>
    <!-- end of global search-->
    <span class="example-spacer"></span>
    <span style="padding-right: 25px;">
      <button
        *ngIf="adminType !== adminTypes.CMO"
        mat-icon-button
        [matMenuTriggerFor]="list"
        aria-label="Example icon-button with a menu"
      >
        {{ "List" | translate }}
      </button>
      <mat-menu #list="matMenu">
        <button
          mat-menu-item
          (click)="routingToModule('/admin')"
          *ngIf="adminType === adminTypes.CEO"
        >
          <span>{{ "Admin List" | translate }}</span>
        </button>
        <button mat-menu-item (click)="routingToModule('/agents')">
          <span>{{ "Agent List" | translate }}</span>
        </button>
        <mat-divider *ngIf="adminType === adminTypes.CEO"></mat-divider>
        <button mat-menu-item (click)="routingToModule('/patients')">
          <span>{{ "Patient List" | translate }}</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="routingToModule('/doctor')">
          <span>{{ "Doctor List" | translate }}</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="routingToModule('/org')">
          <span>{{ "Organization List" | translate }}</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="routingToModule('/allies')">
          <span>{{ "List of allies" | translate }}</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="routingToModule('/allConsults')">
          <span>{{ "All Consults List" | translate }}</span>
        </button>
      </mat-menu>
    </span>
    <span
      style="font-size: 0.875rem; padding-right: 35px; cursor: pointer;"
      (click)="termCondition()"
      *ngIf="adminType !== adminTypes.CMO"
    >
      {{ "TC & PP" | translate }}
    </span>
    <span
      style="font-size: 0.875rem; padding-right: 35px; cursor: pointer;"
      (click)="routingToModule('/logs')"
      *ngIf="adminType === adminTypes.CMO"
    >
      {{ "Logs" | translate }}
    </span>
    <span
      style="font-size: 0.875rem; padding-right: 35px; cursor: pointer;"
      (click)="routingToModule('/allConsults')"
      *ngIf="adminType === adminTypes.CMO"
    >
      {{ "All Consults List" | translate }}
    </span>
    <span style="padding-right: 25px;">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <ngx-avatar [name]="userName" size="40"></ngx-avatar>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="changePassword()">
          <mat-icon color="warn"
            ><img src="../../../assets/images/lock.svg"
          /></mat-icon>
          <span>{{ "Change Password" | translate }}</span>
        </button>
        <mat-divider></mat-divider>
        <!-- <button mat-menu-item (click)="changeLanguage('en-US')">
          <mat-icon>language</mat-icon>
          <span>English</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="changeLanguage('es-ES')">
          <mat-icon>language</mat-icon>
          <span>Spanish</span>
        </button>
        <mat-divider></mat-divider> -->
        <button mat-menu-item (click)="logOut()">
          <mat-icon color="warn"
            ><img src="../../../assets/images/power.svg"
          /></mat-icon>
          <span style="color: #cd252d;">{{ "Logout" | translate }}</span>
        </button>
      </mat-menu>
    </span>
  </mat-toolbar-row>
</mat-toolbar>
