import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class CommonService {
  public IsWait: boolean = false;

  constructor(private _snackBar: MatSnackBar) {}
  success(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 1500,
      verticalPosition: "top",
      panelClass: ["success-bg-color"],
    });
  }

  error(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 1200,
      verticalPosition: "top",
      panelClass: ["warn-bg-color"],
    });
  }
}
