import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageComponent } from "../message/message.component";

@Injectable({
  providedIn: "root",
})
export class TermPolicyService {
  constructor(private http: HttpClient, public snackBar: MatSnackBar) {}

  addAgreement(formValues: any) {
    return this.http.post(`/agreements`, formValues);
  }

  getTermsConditionDoctor() {
    return this.http.get<any>(`/agreements/2?agreementType=1`);
  }
  getPrivacyPolicyDoctor() {
    return this.http.get<any>(`/agreements/2?agreementType=2`);
  }
  getTermsConditionPatient() {
    return this.http.get<any>(`/agreements/1?agreementType=1`);
  }
  getPrivacyPolicyPatient() {
    return this.http.get<any>(`/agreements/1?agreementType=2`);
  }
  showMessage(message: string, panelClass: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: message,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
      panelClass: panelClass,
    });
  }
}
