<div class="text-area-component">
  <label class="subheading text-area-component__label">{{ label }}</label>
  <div
    *ngIf="edit"
    [innerHtml]="html"
    contenteditable="true"
    (keyup)="changeEvent($event)"
    class="text-area-component__input text-area-component__input--edit"
  ></div>
  <div
    *ngIf="!edit"
    [innerHtml]="!!data ? data : 'Enter text...'"
    class="text-area-component__input"
  ></div>
  <input [id]="controller" [formControlName]="controller" />
</div>
