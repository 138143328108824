<div>
  <div class="containerterm">
    <p class="terms">{{ "Terms and condions & Privacy Policy" | translate }}</p>
    <br />
    <form [formGroup]="AgreementForm">
      <mat-tab-group mat-align-tabs="center">
        <mat-tab label="{{ 'Patients' | translate }}">
          <br />
          <div>
            <div class="TC" *ngIf="!editPatiensTerms">
              {{ "Terms and condions" | translate }}
              <span
                class="savebutton"
                (click)="termConditionPatient()"
                style="cursor: pointer;"
                >
                {{ "Save" | translate }}
                <img class="btn-icon" src="../../../assets/images/save.svg" />
              </span>
            </div>
            <div *ngIf="editPatiensTerms" class="TC">
              {{ "Terms and condions" | translate }}
              <span
                class="savebutton"
                (click)="editPatientTerms()"
                style="cursor: pointer;"
                >{{ "Edit" | translate }}
                <img class="btn-icon" src="../../../assets/images/edit.svg" />
              </span>
            </div>
            <br />
            <angular-editor
              formControlName="termPatient"
              [config]="editorConfigPatientTerm"
            ></angular-editor>
          </div>
          <br /><br />
          <div>
            <div class="TC" *ngIf="!editPatientpolicy">
              {{ "Privacy Policy" | translate
              }}<span
                class="savebutton"
                (click)="privacyPolicyPatient()"
                style="cursor: pointer;"
                >{{ "Save" | translate }}
                <img class="btn-icon" src="../../../assets/images/save.svg" />
              </span>
            </div>
            <div *ngIf="editPatientpolicy" class="TC">
              {{ "Privacy Policy" | translate }}

              <span
                class="savebutton"
                (click)="editPatientPolicy()"
                style="cursor: pointer;"
                >{{ "Edit" | translate }}
                <img class="btn-icon" src="../../../assets/images/edit.svg" />
              </span>
            </div>
            <br />
            <angular-editor
              formControlName="policyPatient"
              [config]="editorConfigPatientPolicy"
            ></angular-editor>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'Doctors' | translate }}">
          <br />
          <div>
            <div class="TC" *ngIf="!editterm">
              {{ "Terms and condions" | translate
              }}<span
                class="savebutton"
                style="cursor: pointer;"
                (click)="termConditionDoctor()"
                >{{ "Save" | translate }}
                <img class="btn-icon" src="../../../assets/images/save.svg" />
              </span>
            </div>
            <div *ngIf="editterm" class="TC">
              {{ "Terms and condions" | translate }}

              <span
                class="savebutton"
                style="cursor: pointer;"
                (click)="editDoctorTerm()"
                >{{ "Edit" | translate }}
                <img class="btn-icon" src="../../../assets/images/edit.svg" />
              </span>
            </div>
            <br />
            <angular-editor
              formControlName="termDoctor"
              [config]="editorConfigDoctorTerm"
            ></angular-editor>
          </div>
          <br /><br />
          <div>
            <div class="TC" *ngIf="!editpolicy">
              {{ "Privacy Policy" | translate }}
              <span
                class="savebutton"
                style="cursor: pointer;"
                (click)="privacyPolicyDoctor()"
                >
                  {{ "Save" | translate }}
                <img class="btn-icon" src="../../../assets/images/save.svg" />
              </span>
            </div>
            <div *ngIf="editpolicy" class="TC">
              {{ "Privacy Policy" | translate }}
              <span
                class="savebutton"
                style="cursor: pointer;"
                (click)="editDoctorPolicy()"
                >{{ "Edit" | translate }}
                <img class="btn-icon" src="../../../assets/images/edit.svg" />
              </span>
            </div>
            <br />
            <angular-editor
              formControlName="policyDoctor"
              [config]="editorConfigDoctorPolicy"
            ></angular-editor>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>
</div>
