import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { Subject, ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DetailService } from "src/app/home/allconsult/detail.service";
import { SharedService } from "../shared.service";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: "app-attachment-poupu",
  templateUrl: "./attachment-poupu.component.html",
  styleUrls: ["./attachment-poupu.component.scss"],
})
export class AttachmentPoupuComponent implements OnInit {
  attachForm: FormGroup;
  prescription = false;
  tags: any = [];
  updateAttachments;
  attachmentDetails: any;
  attachmentId;
  documents: any;
  description: any;
  tagsample: any;
  documents2: any;
  description2: any;
  tagsample2: any;
  documentId: any;
  editSampleTag: any;
  attachData;
  bodyTags;
  public singPatientRecord: any;
  attachmentOnCall: any;
  attachmentData: any;
  callStatusData;
  // hiding: boolean = false;
  png = false;
  jpg = false;
  pdf = false;
  doc = false;
  xsl = false;
  currentLanguange: string;
  public fcFilterCtrl: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();
  public filteredCategoriesMulti: ReplaySubject<any[]> = new ReplaySubject<
    any[]
  >(1);

  constructor(
    private dashboardService: DetailService,
    public dialog: MatDialog,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<AttachmentPoupuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.translateService.currentLang == "en-US") {
      this.currentLanguange = "en";
    } else {
      this.currentLanguange = "es";
    }
    this.attachmentOnCall = this.data["imageRecord"];
    this.attachmentDetails = this.data["document"];
    this.callStatusData = this.data["status"];

    if (this.attachmentDetails) {
      this.documents = this.attachmentDetails.url;
      this.documentId = this.attachmentDetails._id;
      this.description = this.attachmentDetails.description
        ? this.attachmentDetails.description
        : "";
      this.tagsample = this.attachmentDetails.tag
        ? this.attachmentDetails.tag.tag.en
        : "";
      var url = new URL(this.attachmentDetails.url);
      var path = url.pathname;
      const imageExtension = path.split(".").pop();
      if (imageExtension === "png") {
        this.png = true;
      }
      if (imageExtension === "jpg") {
        this.jpg = true;
      }
      if (imageExtension === "pdf") {
        this.pdf = true;
      }
      if (imageExtension === "docx" || imageExtension === "doc") {
        this.doc = true;
      }
      if (
        imageExtension === "xls" ||
        imageExtension === "xlsx" ||
        imageExtension === "csv"
      ) {
        this.xsl = true;
      }
    }
    this.attachForm = this.fb.group({
      tags: [""],
      description: [""],
    });

    this.getallTags();
  }

  getallTags() {
    this.dashboardService.getTags().subscribe(
      (res: any) => {
        this.tags = res["data"];
      },
      (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    );
  }
  editPrescription() {
    this.prescription = true;
    this.attachForm.patchValue({
      tags: this.tagsample,
      description: this.description,
    });
  }

  changeTags(data) {}

  updateAttachment() {
    this.attachData = this.attachForm.value;
    for (let t of this.tags) {
      if (this.attachData.tags == t.tag.en) {
        this.bodyTags = t._id;
      }
    }
    let data = {
      tag: this.bodyTags,
      description: this.attachData.description,
    };
    this.dashboardService.getupdateAttachement(this.documentId, data).subscribe(
      (res: any) => {
        this.updateAttachments = res["data"];
        if (this.updateAttachments) {
          this.documents = this.updateAttachments.url;
          this.description = this.updateAttachments.description;
          this.tagsample = this.updateAttachments.tag.tag.en;
        }

        this.translateService
          .get("Documents has been updated successfully")
          .subscribe((text: string) => {
            this.dashboardService.showMessage(text, "success");
          });
        this.dialog.closeAll();
      },
      (err: HttpErrorResponse) => {
        this.handleError(err);
      }
    );
  }

  doneAttachment() {
    this.dialog.closeAll();
  }
  closePopup() {
    this.dialog.closeAll();
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
