<div class="container">
  <mat-icon style="margin-left: 90%;" mat-button (click)="closePopup()"
    >close</mat-icon
  >
  <div>
    <a target="_blank" href="{{ this.documents }}" *ngIf="png"
      ><img src="{{ this.documents }}" class="img" /> <br
    /></a>
    <a target="_blank" href="{{ this.documents }}" *ngIf="jpg"
      ><img src="{{ this.documents }}" class="img" /> <br
    /></a>
    <a target="_blank" href="{{ this.documents }}" *ngIf="doc"
      ><img src="../../../assets/images/word.png" class="img" /> <br
    /></a>
    <a target="_blank" href="{{ this.documents }}" *ngIf="pdf"
      ><img src="../../../assets/images/pdfs.png" class="img" /> <br
    /></a>
    <a target="_blank" href="{{ this.documents }}" *ngIf="xsl"
      ><img src="../../../assets/images/excel.png" class="img" /> <br
    /></a>
    <div>
      <!-- <div>    -->
      <span
        *ngIf="!prescription"
        style="float: right; color: #65a7f6; cursor: pointer;"
        (click)="editPrescription()"
      >
        <!-- <img src="../../../assets/images/editsss.png"
      /> -->
        <mat-icon>edit</mat-icon>
      </span>
    </div>
    <br />
    <div *ngIf="!prescription">
      <button class="buttons" *ngIf="tagsample">
        {{ this.tagsample ? this.tagsample : "" }}
      </button>
      <div style="margin: 10%; text-align: left;">
        <span class="content">
          {{ this.description ? this.description : "" }}
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="prescription">
    <form [formGroup]="attachForm">
      <div style="text-align: center;">
        <mat-form-field>
          <mat-label>{{ "Choose a tags" | translate }}</mat-label>
          <mat-select
            formControlName="tags"
            (selectionChange)="changeTags($event.value)"
          >
            <mat-option *ngFor="let t of tags" [value]="t">{{
              t.tag[currentLanguange]
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <br />
      <textarea
        matInput
        rows="5"
        cols="42"
        class="textArea"
        style="resize: none;"
        formControlName="description"
      ></textarea>
    </form>
  </div>
  <div *ngIf="!prescription" class="attachdone-button-row">
    <button mat-raised-button (click)="doneAttachment()">
      {{ "Done" | translate }}
    </button>
  </div>
  <div *ngIf="prescription" class="attach-button-row">
    <button mat-raised-button (click)="updateAttachment()">
      {{ "Save" | translate }}
    </button>
  </div>
</div>
