<div class="container">
  <div class="close">
    <button mat-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="content">
    <img
      src="assets/images/warning.png"
      alt="icon"
      class="icon-warning"
    />

    <div style="text-align: center;" class="content-text">
      <p class="content-header">
        ¿Deseas solicitar la aceptación de los usuarios?
      </p>

      <p class="content-body">
        Esta acción impedirá que los usuarios usen la aplicación si antes no aceptan {{ message }}
      </p>
    </div>

    <div class="actions">
      <button (click)="modalAction('deny')">
        No solicitar
      </button>

      <button (click)="modalAction('accept')">
        Solicitar
      </button>
    </div>
  </div>
</div>
