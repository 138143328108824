import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./home.component";
import { HomeRoutingModule } from "./home-routing.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { MaterialModule } from "../material.module";
import { OrgInsuranceComponent } from "./org-insurance/org-insurance.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { MessageComponent } from "./message/message.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { AllconsultComponent } from "./allconsult/allconsult.component";
import { TermConditionComponent } from "./term-condition/term-condition.component";
import { ConfirmModalComponent } from "./term-condition/confirm-modal/confirm-modal.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { BreadcrumbModule } from "xng-breadcrumb";

@NgModule({
  declarations: [
    HomeComponent,
    MessageComponent,
    TermConditionComponent,
    ChangePasswordComponent,
    ConfirmModalComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AngularEditorModule,
    SharedModule,
    TranslateModule,
    MaterialModule,
    BreadcrumbModule,
  ],
})
export class HomeModule {}
