import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ThrowStmt } from "@angular/compiler";

@Component({
  selector: "app-text-area",
  templateUrl: "./text-area.component.html",
  styleUrls: ["./text-area.component.scss"],
})
export class TextAreaComponent implements OnInit {
  @Input() controller: string;
  @Input() data: string;
  @Input() label: string = "Input Field";
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() edit: boolean = true;
  public html = "";
  public areaData = "";
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.html = this.data;
    }, 1000);
  }

  changeEvent(e: any) {
    this.change.emit(e.target.innerHTML);
  }
}
