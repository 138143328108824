import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { TermPolicyService } from "./term-policy.service";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/common.services";
import { SharedService } from "src/app/shared/shared.service";

import { MatDialog } from "@angular/material/dialog";
import { ConfirmModalComponent, ModalConfirmActions, confirmModalConfig } from "./confirm-modal/confirm-modal.component";

export const enum userType {
  DOCTOR = 2,
  PATIENT = 1,
}

export const enum agreementType {
  TERMS_CONDITION = 1,
  PRIVACY_POLICY = 2,
}
@Component({
  selector: "app-term-condition",
  templateUrl: "./term-condition.component.html",
  styleUrls: ["./term-condition.component.scss"],
})
export class TermConditionComponent implements OnInit {
  AgreementForm: FormGroup;
  editterm: boolean = true;
  editpolicy: boolean = true;
  editPatiensTerms: boolean = true;
  editPatientpolicy: boolean = true;

  termDoctor: any;
  termPatient: any;
  policyDoctor: any;
  disabled: boolean = false;
  policyPatient: any;
  editorConfigPatientTerm: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    height: "200px",
    minHeight: "100px",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      ["heading", "fontName"],
      [
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
        "toggleEditorMode",
        "fontSize",
        "textColor",
        "backgroundColor",
        "customClasses",
      ],
    ],
  };
  editorConfigPatientPolicy: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    height: "200px",
    minHeight: "100px",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      ["heading", "fontName"],
      [
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
        "toggleEditorMode",
        "fontSize",
        "textColor",
        "backgroundColor",
        "customClasses",
      ],
    ],
  };
  editorConfigDoctorTerm: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    height: "200px",
    minHeight: "100px",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      ["heading", "fontName"],
      [
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
        "toggleEditorMode",
        "fontSize",
        "textColor",
        "backgroundColor",
        "customClasses",
      ],
    ],
  };
  editorConfigDoctorPolicy: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    height: "200px",
    minHeight: "100px",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      ["heading", "fontName"],
      [
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
        "toggleEditorMode",
        "fontSize",
        "textColor",
        "backgroundColor",
        "customClasses",
      ],
    ],
  };
  constructor(
    private fb: FormBuilder,
    private commonServices: CommonService,
    private service: TermPolicyService,
    private sharedService: SharedService,
    private translateService: TranslateService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.AgreementForm = this.fb.group({
      termPatient: [""],
      policyPatient: [""],
      termDoctor: [""],
      policyDoctor: [""],
    });
    this.getTermsConditionDoctor();
    this.getPrivacyPolicyDoctor();
    this.getTermsConditionPatient();
    this.getPrivacyPolicyPatient();
  }

  termConditionPatient() {
    const dataTermConditionPatient = {
      content: this.AgreementForm.value.termPatient,
      userType: userType.PATIENT,
      agreementType: agreementType.TERMS_CONDITION,
    };

    /** todo:
     * LLamar al modal de confirmación
     * Se debe llamar al modal una ves se guarde la informacion con éxito
    */
    confirmModalConfig['data'] = {
      origin: "termConditionPatient",
      message: "los términos y condiciones",
    }

    const dialogRef =  this.dialog.open(ConfirmModalComponent, confirmModalConfig);
  
    dialogRef.afterClosed().subscribe((result) => {
      dataTermConditionPatient['requestAcceptance'] = result === ModalConfirmActions.ACCEPT ? true : false;
      this.service.addAgreement(dataTermConditionPatient).subscribe(
        (res: any) => {
          this.translateService
            .get("Terms Condition has been added successfully")
            .subscribe((text: string) => {
              this.service.showMessage(text, "success");
              this.editPatiensTerms = true;
              this.editorConfigPatientTerm.editable = false;
            });
        },
        (error: HttpErrorResponse) => {
          this.handleError(error);
        }
      );
    });
  }

  privacyPolicyPatient() {
    let dataPrivacyPolicyPatient = {
      content: this.AgreementForm.value.policyPatient,
      userType: userType.PATIENT,
      agreementType: agreementType.PRIVACY_POLICY,
    }

    confirmModalConfig['data'] = {
      origin: "privacyPolicyPatient",
      message: "las políticas de privacidad",
    }

    const dialogRef =  this.dialog.open(ConfirmModalComponent, confirmModalConfig);

    dialogRef.afterClosed().subscribe((result) => {

      dataPrivacyPolicyPatient['requestAcceptance'] = result === ModalConfirmActions.ACCEPT ? true : false;

      this.service.addAgreement(dataPrivacyPolicyPatient).subscribe(
        (res: any) => {
          this.translateService
            .get("Privacy Policy has been added successfully")
            .subscribe((text: string) => {
              this.service.showMessage(text, "success");
              this.editPatientpolicy = true;
              this.editorConfigPatientPolicy.editable = false;
            });
        },
        (error: HttpErrorResponse) => {
          this.handleError(error);
        }
      );
    });
  }

  termConditionDoctor() {
    let data = {
      content: this.AgreementForm.value.termDoctor,
      userType: 2,
      agreementType: 1,
    };
    this.service.addAgreement(data).subscribe(
      (res: any) => {
        this.translateService
          .get("Terms Condition has been added successfully")
          .subscribe((text: string) => {
            this.service.showMessage(text, "success");
            this.editterm = true;
            this.editorConfigDoctorTerm.editable = false;
          });
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  privacyPolicyDoctor() {
    let data = {
      content: this.AgreementForm.value.policyDoctor,
      userType: 2,
      agreementType: 2,
    };
    this.service.addAgreement(data).subscribe(
      (res: any) => {
        this.translateService
          .get("Privacy Policy has been added successfully")
          .subscribe((text: string) => {
            this.service.showMessage(text, "success");
            this.editpolicy = true;
            this.editorConfigDoctorPolicy.editable = false;
          });
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  editPatientPolicy() {
    this.editorConfigPatientPolicy.editable = true;
    this.editPatientpolicy = false;
  }
  editPatientTerms() {
    this.editorConfigPatientTerm.editable = true;
    this.editPatiensTerms = false;
  }
  editDoctorTerm() {
    this.editorConfigDoctorTerm.editable = true;
    this.editterm = false;
  }
  editDoctorPolicy() {
    this.editorConfigDoctorPolicy.editable = true;
    this.editpolicy = false;
  }
  getTermsConditionDoctor() {
    this.AgreementForm.disable();
    this.service.getTermsConditionDoctor().subscribe(
      (res: any) => {
        this.termDoctor = res.data[0];
        if (this.termDoctor) {
          this.editterm = true;
        }
        this.AgreementForm.patchValue({
          termDoctor: res.data[0].content,
        });
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }
  getPrivacyPolicyDoctor() {
    this.service.getPrivacyPolicyDoctor().subscribe(
      (res: any) => {
        this.policyDoctor = res.data[0];
        if (this.policyDoctor) {
          this.editpolicy = true;
        }
        this.AgreementForm.patchValue({
          policyDoctor: res.data[0].content,
        });
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  getTermsConditionPatient() {
    this.service.getTermsConditionPatient().subscribe(
      (res: any) => {
        this.termPatient = res.data[0];
        if (this.termPatient) {
          this.editPatiensTerms = true;
        }
        this.AgreementForm.patchValue({
          termPatient: res.data[0].content,
        });
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }
  getPrivacyPolicyPatient() {
    this.service.getPrivacyPolicyPatient().subscribe(
      (res: any) => {
        this.policyPatient = res.data[0];
        if (this.policyPatient) {
          this.editPatientpolicy = true;
        }
        this.AgreementForm.patchValue({
          policyPatient: res.data[0].content,
        });
      },
      (error: HttpErrorResponse) => {
        this.handleError(error);
      }
    );
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
