<div class="reset-container">
  <div class="container">
    <div style="text-align: center;">
      <h4 class="heading">{{ "Set New Password" | translate }}</h4>
    </div>
    <!-- <p class="subheading">Please enter your email below</p> -->
    <mat-card class="reset-card" *ngIf="!hideForm">
      <form
        class="example-form"
        [formGroup]="changepasswordForm"
        (ngSubmit)="changePasswordSubmit()"
      >
        <p style="text-align: initial; color: #46535b; font-weight: 600;">
          {{
            "Please enter your new password and confirm password." | translate
          }}
        </p>

        <mat-form-field
          class="example-full-width app-form-field"
          style="width: 100%;"
        >
          <input
            [type]="oldPasswordHide ? 'password' : 'text'"
            matInput
            placeholder="{{ 'Old Password' | translate }}"
            formControlName="oldPassword"
            required
          />
          <mat-icon matSuffix (click)="oldPasswordHide = !oldPasswordHide">{{
            oldPasswordHide ? "visibility_off" : "visibility"
          }}</mat-icon>
          <mat-error
            *ngIf="changepasswordForm.get('oldPassword').hasError('required')"
          >
            {{ "Old Password is Required." | translate }}
          </mat-error> </mat-form-field
        ><br />
        <mat-form-field
          class="example-full-width app-form-field"
          style="width: 100%;"
        >
          <input
            [type]="passwordHide ? 'password' : 'text'"
            matInput
            placeholder="{{ 'New Password' | translate }}"
            formControlName="password"
            required
          />
          <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{
            passwordHide ? "visibility_off" : "visibility"
          }}</mat-icon>
          <mat-error
            *ngIf="changepasswordForm.get('password').hasError('required')"
          >
            {{ "Password is Required." | translate }}
          </mat-error>
          <mat-error
            *ngIf="changepasswordForm.get('password').hasError('pattern')"
          >
            {{ "Please enter valid password." | translate }}
          </mat-error>
          <mat-error
            *ngIf="changepasswordForm.get('password').hasError('minlength')"
          >
            {{ "Password should be minimum of 8 characters." | translate }}
          </mat-error>
        </mat-form-field>
        <br />
        <mat-form-field
          class="example-full-width app-form-field"
          style="width: 100%;"
        >
          <input
            [type]="confirmPasswordHide ? 'password' : 'text'"
            matInput
            placeholder="{{ 'Confirm Password' | translate }}"
            formControlName="confirmPassword"
            required
          />

          <mat-icon
            matSuffix
            (click)="confirmPasswordHide = !confirmPasswordHide"
            >{{
              confirmPasswordHide ? "visibility_off" : "visibility"
            }}</mat-icon
          >
          <mat-error
            *ngIf="
              changepasswordForm.get('confirmPassword').hasError('required')
            "
          >
            {{ "Confirm Password is Required." | translate }}
          </mat-error>
          <mat-error
            *ngIf="
              changepasswordForm.get('confirmPassword').hasError('mustMatch')
            "
          >
            {{ "Password and confirm password do not match" | translate }}
          </mat-error>
        </mat-form-field>

        <p *ngIf="showError" style="font-weight: bold; color: red;">
          {{ errorMsg | translate }}
        </p>

        <div class="reset-button-row">
          <button
            [disabled]="loading"
            mat-raised-button
            style="margin-left: 5%;"
          >
            {{ "Change" | translate }}
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
