<div class="wrapper">
  <img src="./../../assets/404.svg" alt="404 Image" />
  <p>The page you are looking for is not found or internet is not stable</p>
  <button
    class="btn-custom"
    [routerLink]="'/'"
    mat-raised-button
    color="primary"
  >
    Back to Dashboard
  </button>
</div>
