import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { MessageComponent } from "../message/message.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(private http: HttpClient, public snackBar: MatSnackBar) {}

  /**
   * Assign params with key value pare
   * @param params: any
   */
  private getHttpParams(params) {
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key];
        httpParams = httpParams.set(key, element);
      }
    }
    return httpParams;
  }

  getAdminsList(params) {
    const httpParams = this.getHttpParams(params);
    return this.http.get(`/admins/`, { params: httpParams });
  }

  addAdminsList(formValues: any) {
    return this.http.post(`/admins`, formValues);
  }

  editAdminsList(formValues: any, id: string) {
    return this.http.put(`/admins/${id}`, formValues);
  }

  deleteAdminsList(id: string) {
    return this.http.delete(`/admins/${id}`);
  }

  activateAdmin(id: string) {
    return this.http.patch(`/admins/${id}/activate`, {});
  }

  deactivateAdmin(id: string) {
    return this.http.patch(`/admins/${id}/deactivate`, {});
  }

  downloadReport() {
    return this.http.get(`/admins/report`, { responseType: "text" });
  }

  deleteAdmin(id: string) {
    return this.http.delete(`/admins/${id}`);
  }

  showMessage(message: string, panelClass: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: message,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
      panelClass: panelClass,
    });
  }
}
