import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[shdAddSymbol]',
  })
export class AddSymbolDirective {
    @Input() symbolPosition: 'start' | 'end' = 'end';
    @Input() symbolToAdd: string = '';
    constructor(private el: ElementRef) {}
  
    @HostListener('input', ['$event']) onInputChange(event: any) {
      let initialValue = this.el.nativeElement.value;
      const pattern = /[0-9]/;
      
      const onlyNumbers = pattern.test(initialValue);
      console.log(initialValue)
      if(event.inputType === 'deleteContentBackward' && !initialValue.includes(this.symbolToAdd)) {
        initialValue = initialValue.replace(this.symbolToAdd,'').slice(0, -1)

      }
      let symbol = ''
      if(onlyNumbers && initialValue.length > 0) {
        symbol = this.symbolToAdd
      }
      if(this.symbolPosition === 'end'){
        this.el.nativeElement.value = `${initialValue.replace(/[^0-9]*/g, '')}${symbol}`
      }else {
         this.el.nativeElement.value = `${symbol}${initialValue.replace(/[^0-9]*/g, '')}`
      }
     
      if (initialValue !== this.el.nativeElement.value) {
        event.stopPropagation();
      }
    }
  }