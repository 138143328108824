import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { MessageComponent } from "../message/message.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class DetailService {
  constructor(private http: HttpClient, public snackBar: MatSnackBar) {}

  getPatientDetails(id) {
    return this.http.get(`/patients/${id}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getMedicalHistory(param) {
    let params = new HttpParams();
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        params = params.set(key, param[key]);
      }
    }
    return this.http.get(`/medical-histories`, { params: params }).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getTags() {
    return this.http.get(`/tags`);
  }

  getupdateAttachement(id: number, data: object) {
    return this.http.put(`/documents/${id}`, data);
  }

  /**
   * Assign params with key value pare
   * @param params: any
   */
  private getHttpParams(params) {
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key];
        httpParams = httpParams.set(key, element);
      }
    }
    return httpParams;
  }

  getDocuments(param: object) {
    const httpParams = this.getHttpParams(param);
    return this.http.get(`/documents`, { params: httpParams });
  }

  getIllness() {
    return this.http.get(`/illnesses`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getDisease() {
    return this.http.get(`/diseases`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  editMedicalHistory(data: any) {
    return this.http.post(`/medical-histories`, data);
  }

  getConsultationList(param: object) {
    const httpParams = this.getHttpParams(param);
    return this.http.get(`/consultations`, { params: httpParams });
  }

  getConsultationDetails(id: string) {
    //const httpParams = this.getHttpParams(param);
    return this.http.get(`/consultations/${id}`, {});
  }

  getbodySystem() {
    return this.http.get(`/body-systems`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  getAttachmentsInfo(id) {
    return this.http.get(`/documents/${id}`);
  }

  getUnits() {
    return this.http.get("/units");
  }
  getRoute() {
    return this.http.get("/route-of-adminstrations");
  }
  getTimes() {
    return this.http.get("/times");
  }

  removeAlternativeMedicine(id: string) {
    return this.http.delete(`/medical-prescriptions/${id}`);
  }

  removeMedicine(id: string) {
    return this.http.delete(`/medical-prescriptions/${id}`);
  }

  removeParaClinicalPrescription(id: string) {
    return this.http.delete(`/paraclinical-prescriptions/${id}`);
  }

  updateVisitHistory(id: string, data: object) {
    return this.http.put(`/consultations/${id}/visit-summaries`, data);
  }

  getUserDetails(id) {
    return this.http.get(`/patients/${id}`).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  showMessage(message: string, panelClass: string) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: message,
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: 10000,
      panelClass: panelClass,
    });
  }
}
