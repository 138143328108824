import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CredentialsService } from "../core/credentials.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constructor(
    private router: Router,
    private credService: CredentialsService
  ) {}

  ngOnInit(): void {}

  gotoLandingPage() {
    if (this.credService.isAuthenticated()) {
      this.router.navigate(["/"]);
    } else {
      this.router.navigate(["/login"]);
    }
  }
}
