import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as passwordHash from "js-sha512";
import { ErrorStateMatcher } from "@angular/material/core";
import { TranslateService } from "@ngx-translate/core";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { MustMatch } from "./must-match.validator";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthenticationService } from "src/app/core/authentication.service";
import { HomeService } from "../home.service";
import { SharedService } from "src/app/shared/shared.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  changepasswordForm: FormGroup;
  passwordHide = true;
  confirmPasswordHide = true;
  oldPasswordHide = true;
  errorMsg: any;
  showError: boolean = false;
  hideForm = false;
  loading: boolean = false;
  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private homeService: HomeService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private translationService: TranslateService
  ) {}

  ngOnInit() {
    let passwordregex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,15}$/;
    this.changepasswordForm = this.fb.group(
      {
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(passwordregex),
            Validators.minLength(8),
          ],
        ],
        confirmPassword: ["", [Validators.required]],
        oldPassword: ["", [Validators.required]],
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );
  }

  changePasswordSubmit() {
    if (this.changepasswordForm.invalid) {
      return;
    }
    this.loading = true;
    this.showError = false;
    let hashedPassword = passwordHash.sha512(
      this.changepasswordForm.value.password
    );
    let hashedOldPassword = passwordHash.sha512(
      this.changepasswordForm.value.oldPassword
    );
    this.authService.resetPassword(hashedPassword, hashedOldPassword).subscribe(
      (res: any) => {
        this.loading = false;
        this.translationService
          .get("Password changed successfully")
          .subscribe((text: string) => {
            this.homeService.showToastMessage(text, "success");
          });

        this.router.navigate(["/dashboard"]);
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.showError = true;
        this.errorMsg = error.error.errors;
      }
      // (error: HttpErrorResponse)=>{
      //   this.handleError(error);
      // }
    );
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = error.error.message;
      this.sharedService.showErrorMessage(errorMessage, "single");
    } else {
      // server-side error

      if (error.status == 422) {
        let values = [];
        for (let key in error.error.errors.messages) {
          values.push(error.error.errors.messages[key]);
        }
        this.sharedService.showMultipleErrors(values, "multi");
      } else {
        if (typeof error.error.errors == "object") {
          errorMessage = error.error.errors.messages[0];
        } else {
          errorMessage = error.error.errors;
        }
        this.sharedService.showErrorMessage(errorMessage, "single");
      }
    }
  }
}
